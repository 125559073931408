<template>
    <div>
        <InternalKeywords />
    </div>
</template>

<script>
import InternalKeywords from '@/components/settings/internalKeywords/index.vue';

export default {
    name: 'SettingsInternalKeywords',
    components: {
        InternalKeywords,
    },
};
</script>
