var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('GenericCrud',{attrs:{"route-name":"SettingsInternalKeywords","route-action":"list","navbar-title":_vm.$t('settings.keyword.buttonLabel'),"navbar-back-route-without-history":{ name: 'Settings' },"main-list-filter-label":_vm.$t('settings.keyword.filterLabel'),"main-fist-scroll-to-selected":"","storeData":{
            mainListItems: {
                action: 'companyInternalKeywords/fetch',
                payload: { force: true },
                options: null,
                itemText: 'title'
            },
            mainItemById: {
                action: 'companyInternalKeywords/fetchById',
                payload: null,
                options: null,
            },
            edit: {
                action: 'companyInternalKeywords/edit',
                payload: null,
                options: null,
                refetch: true,
                hideButton: true
            },
            add: {
                action: 'companyInternalKeywords/create',
                payload: null,
                options: null,
            },
            delete: {
                action: 'companyInternalKeywords/delete',
                payload: null,
                options: null,
                hideButton: true
            },
            findUsage: {
                action: 'companyInternalKeywords/findUsage',
                payload: null,
                options: null,
                hideButton: true
            },
            replace: {
                action: 'companyInternalKeywords/replaceItemInRecord',
                options: null,
                hideButton: true
            },
        },"newItemModelFields":{
            clientId: null,
            locale: _vm.currentLanguage,
            translations: _vm.defaultTranslations,
        }},scopedSlots:_vm._u([{key:"editorMask",fn:function(x){return [_c('EditorMask',{key:x.id,attrs:{"available-languages":_vm.availableLanguages,"current-language":_vm.currentLanguage,"isWaiting":x.isWaiting,"isModified":x.isModified},on:{"modified":x.onSetModified,"onSaveItem":x.onSaveItem,"onDeleteItem":x.onDeleteItem,"onFindUsage":x.onFindUsage,"onCreateItem":x.onCreateItem},model:{value:(x.selectedMainItem),callback:function ($$v) {_vm.$set(x, "selectedMainItem", $$v)},expression:"x.selectedMainItem"}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }